<template>
  <div
    class="d-inline-flex align-items-center pointer"
    @click="getMemberInfo(playerno, 'player_pk')"
  >
    <template v-if="!no_img">
      <a
        v-if="nickname"
        class="avatar avatar-md me-2"
      >
        <img
          v-if="badge_img"
          :src="badge_img | get_img"
        />
        <img
          v-else-if="member_img"
          :src="member_img | get_img"
        />
        <span v-else class="material-icons default-icon-profile">person</span>
      </a>
    </template>
    <span class="text-nickname">{{ nickname }}</span>

    <b-modal
      id="modal-info-member"
      ref="modal-info-member"
      size="lg"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header>
        <h3>{{ $t("title.common.member_info") }}</h3>
        <b-button
          variant="close"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-info-member')"
        >
        </b-button>
      </template>

      <p class="modal-subtitle">{{ $t("title.common.basic_info") }}</p>
      <div
        class="row g-0 row-cols-1 row-cols-sm-2 row-cols-lg-4 table-type header-col p-keep"
      >
        <div class="col-sm">
          {{ $t("title.common.nickname") }}
        </div>
        <div class="col-sm text-nickname">
          {{ playerbasicinfo && playerbasicinfo.nickname }}
        </div>
        <div class="col-sm">
          {{ $t("title.common.gender") }}
        </div>
        <div class="col-sm">
          {{ playerbasicinfo && changeGender(playerbasicinfo.gender) }}
        </div>

        <div class="col-sm border-bottom-lg-0">
          {{ $t("title.common.favorite_location") }}
        </div>
        <div class="col-sm shop-name border-bottom-lg-0">
          {{ playerbasicinfo.shopname }}
        </div>
        <div class="col-sm">
          {{ $t("title.common.join_date") }}
        </div>
        <div class="col-sm text-date">
          {{
            playerbasicinfo.timezone_date &&
            playerbasicinfo.timezone_date.timezone_common | dateformat
          }}
        </div>
      </div>

      <div
        class="d-flex align-items-baseline flex-column flex-sm-row mt-5 mb-3"
      >
        <p class="modal-subtitle mb-0">
          {{ $t("title.common.round_summary") }}
        </p>
        <p class="ms-0 ms-sm-1 mt-2 mt-sm-0 text-subscript text-green">
          ({{ $t("text.common.18hole_finish") }}*)
        </p>
      </div>
      <div
        class="row g-0 row-cols-1 row-cols-sm-2 row-cols-lg-4 table-type header-col p-keep"
      >
        <div class="col-sm">
          {{ $t("title.common.avg_putts_distance") }}
        </div>
        <div class="col-sm">{{ playerpalyinfo.avggreenhit | toFixed }}m</div>
        <div class="col-sm">
          {{ $t("title.common.total_rounds") }}
        </div>
        <div class="col-sm">
          {{ playerpalyinfo.playcnt || 0 | comma }}
        </div>

        <div class="col-sm">
          {{ $t("title.common.avg_distance") }}
        </div>
        <div class="col-sm">{{ playerpalyinfo.avgdriver | toFixed }}m</div>
        <div class="col-sm">
          {{ $t("title.common.fareway_hit") }}
        </div>
        <div class="col-sm">{{ playerpalyinfo.farewayrate | toFixed }}%</div>

        <div class="col-sm">
          {{ $t("title.common.gir") }}
        </div>
        <div class="col-sm">{{ playerpalyinfo.greenrate | toFixed }}%</div>
        <div class="col-sm">
          {{ $t("title.common.par_save_rate") }}
        </div>
        <div class="col-sm">{{ playerpalyinfo.parsaverate | toFixed }}%</div>

        <div class="col-sm border-bottom-lg-0">
          {{ $t("title.common.avg_stroke") }}
        </div>
        <div class="col-sm border-bottom-lg-0">
          {{ playerpalyinfo.avgshot | toFixed | comma }}
        </div>
        <div class="col-sm">
          {{ $t("title.common.best_stroke") }}
        </div>
        <div class="col-sm">
          {{ playerpalyinfo.minshot | comma }}
        </div>
      </div>

      <TermDefinition getname="score" />

      <template #modal-footer>
        <b-button
          size="md"
          variant="outline-secondary"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-info-member')"
        >
          {{ $t("button.common.close") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TermDefinition from "@/components/TermDefinition";
import ax from "@/api/tournament";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";
export default {
  name: "MemberInfo",
  props: {
    nickname: String,
    playerno: String,
    member_img: String,
    badge_img: String,
    no_img: Boolean,
  },
  components: {
    TermDefinition,
  },
  data() {
    return {
      // playerno: "",
      playerbasicinfo: [],
      playerpalyinfo: [],
    };
  },
  methods: {
    getMemberInfo(player, type) {
      ax.get_playerinfo(player, type, (flag, data) => {
        if (flag) {
          // this.playerno = playerno;
          this.playerbasicinfo = data.playerbasicinfo;
          this.playerpalyinfo = data.playerpalyinfo;
          //this.$refs["modal-info-member"].modalOuterStyle.zIndex = 9999;
          this.$refs["modal-info-member"].show();
        } else {
          alert(data);
        }
      });
    },
  },
  mixins: [myMixin],
};
</script>
